import { usePropsObject } from "@/lib/contexts/appContext"
import Image from "next/image"
import Block from "@/components/wrappers/block"
import styles from "./textImageBlock.module.css"
import classNames from "classnames"
import { themePlateColor, themeTextColor } from "@/lib/helpers/utils"

export default function TextImageBlock({
  block,
  image,
  layout,
  children,
  hovered = false,
  plateColor,
  padding = true
}) {
  const { theme } = usePropsObject()

  if (!plateColor) {
    plateColor = theme?.plateColor
  }

  let textColor = themeTextColor(theme?.txtColor, "text-black-100")
  if (plateColor === "lime") {
    textColor = "text-black-100"
  }

  if (plateColor === "white" && textColor === "text-white") {
    textColor = "text-black-100"
  }

  return (
    <Block
      block={block}
      width="full"
      className={classNames(styles.wrapper, styles["layout-" + layout], {
        "no-offset": !padding
      })}>
      <div
        className={classNames(
          styles.innerWrapper,
          {
            "no-padding": !padding
          },
          themePlateColor(plateColor),
          textColor
        )}>
        <div
          className={classNames(styles.contentGrid, {
            noImage: !image?.url
          })}>
          {image?.url && (
            <div
              className={classNames(styles.imageHolder, {
                "no-padding": !padding
              })}>
              <figure
                className={classNames(
                  styles.figure,
                  hovered ? "image-hover-effect" : ""
                )}>
                {image?.url && (
                  <Image
                    className={styles.image}
                    src={image.url}
                    width={image.width}
                    height={image.height}
                    sizes={image.sizes}
                    alt={image.alt}
                  />
                )}
                {image?.credit && (
                  <div className="credit">Foto av: {image.credit}</div>
                )}
              </figure>
            </div>
          )}
          <div
            className={classNames(styles.contentWrapper, {
              "no-padding": !padding,
              "is-transparent": plateColor === "transparent"
            })}>
            {children}
          </div>
        </div>
      </div>
    </Block>
  )
}
